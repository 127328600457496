<template>
  <div
    class="shop-order-amount"
    :class="{
      'is-disabled': !isEditable,
      'is-small': isSmall,
    }"
  >
    <div
      v-if="!hideControls"
      class="shop-order-amount__control"
      :class="{ 'is-disabled': quantity === 0 }"
      @click=" quantity === 0 || !isEditable ? null : emit('countDown')"
      @keypress="quantity === 0 || !isEditable ? null : emit('countDown')"
    >
      –
    </div>

    <div
      class="shop-order-amount__quantity"
      :class="{ 'is-disabled': !isEditable }"
    >
      {{ quantity }}
    </div>

    <div
      v-if="!hideControls"
      class="shop-order-amount__control"
      :class="{ 'is-disabled': quantity > max }"
      @click="quantity > max || !isEditable ? null : emit('countUp')"
      @keypress="quantity > max || !isEditable ? null : emit('countUp')"
    >
      +
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    quantity: {
        type: Number,
        default: 0,
    },

    max: {
        type: Number,
        default: 1000,
    },

    hideControls: {
        type: Boolean,
        default: false,
    },

    editable: {
        type: Boolean,
        default: true,
    },

    isSmall: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['countUp', 'countDown']);

const isEditable = inject('is-editable', props.editable); // Prepared for interactive order items
</script>

<style lang="scss" scoped>
.shop-order-amount {
    @include typo-font('regular');

    display: flex;
    align-items: center;
    column-gap: 5px;

    .shop-cart-list__column & {
        @include tablet {
            width: 50%;
            order: 1;
        }
    }
}

.shop-order-amount__control {
    display: flex;
    width: 43px;
    height: 46px;
    align-items: center;
    justify-content: center;
    color: $C_GREEN_OLIVE_LIGHT;
    cursor: pointer;
    font-size: 20px;
    line-height: 1;
    transition: opacity 0.25s ease-in-out;
    user-select: none;

    .is-small & {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }

    &.is-disabled, .is-disabled & {
        cursor: default;
        opacity: 0.3;
        pointer-events: none;
    }

    .shop-cart-popup & {
        @include mobile {
            width: 30px;

            &:first-child {
                justify-content: flex-start;
            }

            &:last-child {
                justify-content: flex-end;
            }
        }
    }
}

.shop-order-amount__quantity {
    @include typo-size('a');
    display: flex;
    width: 46px;
    height: 46px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: $C_GREEN_DARK;
    color: $C_WHITE;
    justify-self: flex-start;

    &.is-disabled {
        background: $C_GREY_DARKEST;
    }
}
</style>
